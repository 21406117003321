import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
  loginUser,
  resendActivationEmail,
} from "@js-shared/lib/actions/userActions";
import { clearErrors } from '@js-shared/lib/actions/errorActions'
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@components/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import { Typography, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { renderErros } from "@js-shared/lib/utils/errorHandler";
import PublicPage from "@components/PublicPage";
import { withRouter } from "next/router";
import DefaultMetadata from "@components/DefaultMetadata";
import dynamic from "next/dynamic";
import { Translation } from "react-i18next";
import SendIcon from "@material-ui/icons/Send";
import { API_URL, APP_ENV } from "@js-shared/lib/config/environment";
import AppleIcon from "@material-ui/icons/Apple";
import Divider from "@material-ui/core/Divider";
import EmailIcon from "@material-ui/icons/Email";

const Footer = dynamic(() => import("@components/Homepage/Footer"));


const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    height: "93vh",
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  orDivider: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    paddingTop: 9,
    paddingBottom: 9,
  },
  googleButton: {
    margin: theme.spacing(0, 0, 1),
    padding: 0,
  },
  appleButton: {
    margin: theme.spacing(0, 0, 1),
    paddingTop: 8,
    paddingBottom: 8,
    backgroundColor: "#000",
    color: "#fff",
    "&:hover": {
      backgroundColor: "grey",
    },
  },
  registerButton: {
    margin: theme.spacing(0, 0, 1),
    paddingTop: 8,
    paddingBottom: 8,
  },
  resendEmail: {
    margin: theme.spacing(1, 0, 0),
  },
});

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      email: undefined,
      password: undefined,
      loading: false,
      message: null,
      googleOauthURL: ""
    };
  }

  componentDidMount() {
    this.props.clearErrors()

    if (APP_ENV === 'local') {
      const GOOGLE_OAUTH_URL = "http://localhost:8000/api/auth/o/google-oauth2/?redirect_uri=http://localhost:3000/oauth/google";
      this.setState({
        googleOauthURL: GOOGLE_OAUTH_URL,
      });

    } else {
      const GOOGLE_OAUTH_URL = `${API_URL}/api/auth/o/google-oauth2/?redirect_uri=${API_URL}/oauth/google`;
      this.setState({
        googleOauthURL: GOOGLE_OAUTH_URL,
      });
    }
  }

  onChangeUsername(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  continueWithGoogle = async () => {
    try {
      const res = await axios.get(
        this.state.googleOauthURL, {
        withCredentials: true,
      });
      window.location.replace(res.data.authorization_url);
    } catch (error) {
    }
  };

  continueWithApple = async () => {
    try {
      const res = await axios.get(
        `${API_URL}/api/auth/o/apple-id/?redirect_uri=${API_URL}/oauth/apple`,
        { withCredentials: true }
      );
      window.location.replace(res.data.authorization_url);
    } catch (error) {
    }
  };

  handleLogin(e) {
    e.preventDefault();

    this.setState({ loading: true });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      this.props.loginUser(
        this.state.email,
        this.state.password,
        () => {
          // Success Callback
          if (this.props.user.isAuthenticated) {
            if (
              this.props.router.asPath &&
              this.props.router.asPath !== "/login"
            ) {
              this.props.router.push(this.props.router.asPath);
            } else {
              this.props.router.push("/stations-map");
            }
          } else {
            this.setState({
              loading: false,
              message: renderErros(this.props.errors),
            });
          }
        },
        () => {
          // Error callback
          this.setState({
            loading: false,
            message: renderErros(this.props.errors),
          });
        }
      );
    } else {
      this.setState({
        loading: false,
        message: renderErros(this.props.errors),
      });
    }
  }

  handleRegisterWithEmail(e) {
    this.props.router.push("/register");
    
  }

  render() {
    const { classes } = this.props;
    return (
      <PublicPage>
        <DefaultMetadata title="EV Loader | Login" />
        <Grid container component="main" className={classes.root}>
          {/* Using Translation component because, we cannot use the translation hook 
        inside a Class component, see https://react.i18next.com/latest/translation-render-prop#what-it-does */}
          <Translation>
            {(t) => (
              <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                  <Form
                    className={classes.form}
                    onSubmit={this.handleLogin}
                    ref={(c) => {
                      this.form = c;
                    }}
                    method="POST"
                  >
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      type="email"
                      id="email"
                      label="Email address"
                      autoComplete="email"
                      autoFocus={false}
                      name="email"
                      value={this.state.email}
                      onChange={this.onChangeUsername}
                      validations={[required]}
                    />

                    <TextField
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      required
                      type="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.onChangePassword}
                      validations={[required]}
                      label="Password"
                      id="password"
                      autoComplete="current-password"
                    />

                    <Grid container>
                      <Grid item xs></Grid>
                      <Grid item style={{ marginBottom: "2px" }}>
                        <Link href="/password/reset" variant="body2">
                          {t("Ξέχασα τον κωδικό μου")}
                        </Link>
                      </Grid>
                    </Grid>

                    {this.props.errors.status  && (
                      <>
                        {this.props.errors.status == 401 ? (
                          <>
                            <Alert severity="error"> {t("401_login_unauthorized")} </Alert>
                            <Button
                              fullWidth
                              className={classes.resendEmail}
                              onClick={() => this.props.resendActivationEmail(this.state.email)}
                              variant="contained"
                              color="#fafafa"
                              disableElevation
                              startIcon={<SendIcon />}
                            >
                              <span>{t("resend_activation_email")}</span>
                            </Button>
                          </>
                        ) : (
                          <Alert severity="error"> {renderErros(this.props.errors)} </Alert>
                        )}
                      </>
                    )}

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={this.state.loading}
                      onClick={(e) => this.handleLogin(e)}
                      name="submit"
                      disableElevation
                      startIcon={
                        this.state.loading && (
                          <CircularProgress
                            color="disabled"
                            size={22}
                            // className={classes.circularProgress}
                          />
                        )
                      }
                    >
                      <span>{t("Σύνδεση")}</span>
                    </Button>

                    <div className={classes.orDivider}>
                      <Box mt={1}></Box>
                      <Divider
                        style={{ margin: 10, width: 400, marginBottom: -3 }}
                      />
                      <Avatar
                        style={{
                          backgroundColor: "#fafafa",
                          color: "grey",
                          marginTop: -12,
                          height: 30,
                          width: 30,
                        }}
                      >
                        <Typography
                          variant="body2"
                          display="block"
                          gutterBottom
                        >
                          {t("ή")}
                        </Typography>
                      </Avatar>
                      <Box mt={-1}></Box>
                    </div>

                    <Button
                      fullWidth
                      variant="contained"
                      // color="primary"
                      className={classes.registerButton}
                      disabled={this.state.loading}
                      onClick={(e) => this.handleRegisterWithEmail(e)}
                      disableElevation
                      startIcon={<EmailIcon style={{ marginRight: 17 }} />}
                    >
                      <span>{t("Εγγραφή με email")}</span>
                    </Button>

                    <Button
                      fullWidth
                      className={classes.appleButton}
                      variant="outlined"
                      disableElevation
                      onClick={this.continueWithApple}
                      startIcon={<AppleIcon style={{ marginRight: 10 }} />}
                    >
                      <span>{t("Συνέχεια με Apple")}</span>
                    </Button>
                    <Button
                      fullWidth
                      className={classes.googleButton}
                      variant="outlined"
                      disableElevation
                      color="white"
                      onClick={this.continueWithGoogle}
                      startIcon={
                        <Avatar variant="square" src={"../images/google.png"} />
                      }
                    >
                      <span>{t("Συνέχεια με Google")}</span>
                    </Button>

                    <CheckButton
                      style={{ display: "none" }}
                      ref={(c) => {
                        this.checkBtn = c;
                      }}
                    />
                  </Form>
                </div>
                <Typography component="h1" variant="h5">
                  {" "}
                  &nbsp;
                </Typography>
              </Container>
            )}
          </Translation>

          {/* Homepage Footer Component */}
          <Footer />
        </Grid>
      </PublicPage>
    );
  }
}

const noop = () => {};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    errors: state.errors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resendActivationEmail: (
      email,
      success_callback = noop,
      error_callback = noop
    ) =>
      dispatch(resendActivationEmail(email, success_callback, error_callback)),
    loginUser: (
      email,
      password,
      success_callback = noop,
      error_callback = noop
    ) => dispatch(loginUser(email, password, success_callback, error_callback)),
    clearErrors: () => dispatch(clearErrors()),
  };
};

export default connect(
  mapStateToProps, // State
  mapDispatchToProps // ActionCreators
)(withStyles(useStyles)(withRouter(Login)));
