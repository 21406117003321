/**
 * Get errors object and build one error message string
 * @param {Object} errorState errors object as extracted from redux state
 * errorState = { msg, status, id } 
 */
export function renderErros(errorState) {
  if (+(errorState.status / 100).toFixed(0) === 5) {
    // If error response status starts from 5 (i.e. 5xx HTTP status code)
    // then this is a server error
    return "An error occured on our server. Please try again later";
  }

  const {
    msg
  } = errorState;
  let errorMessage = "";

  if (typeof msg === "string") {
    errorMessage = msg;
  } else if (typeof msg === "undefined") {
    errorMessage = null;
  } else {
    Object.keys(msg).forEach(errorKey => {
      if (Array.isArray(msg[errorKey])) {
        errorMessage += errorKey.replace("_", " ") + ": ";
        msg[errorKey].forEach(error => {
          if (typeof error === "string") {
            errorMessage += error.replace("null", "empty").replace("blank", "empty").replace("may not", "cannot") + " ";
          } else {
            // TODO: Improve this, do not render as JSON
            errorMessage += JSON.stringify(error);
          }
        });
      } else {
        errorMessage += msg[errorKey] + " ";
      }
    });
  }

  return errorMessage;
}